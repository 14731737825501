import React, { useLayoutEffect, useState } from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Link as ScrollLink } from "react-scroll"
import { split } from "ramda"

const Link = ({ children, ...props }) => {
  const [isHomePath, setIsHomePath] = useState(false)
  const [isPathMatch, setIsPathMatch] = useState(false)
  const [linkPathname, linkHash] = split("#", props.to)
  const isHomeLink = props.to === "/"

  useLayoutEffect(() => {
    const { pathname: windowPathname } = window.location
    const isSamePath =
      windowPathname.replace(/\/$/, "") === linkPathname.replace(/\/$/, "")
    const isSamePathWithHash = isSamePath && linkHash

    setIsHomePath(windowPathname === "/")
    setIsPathMatch(isSamePath || isSamePathWithHash)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const { activeClassName, ...scrollProps } = props

  return (isPathMatch && !isHomeLink) || (isHomeLink && isHomePath) ? (
    <ScrollLink
      duration={300}
      offset={-55}
      smooth
      {...scrollProps}
      to={linkHash ? linkHash : "section-main"}
    >
      {children}
    </ScrollLink>
  ) : linkHash ? (
    <a href={props.to} {...props}>
      {children}
    </a>
  ) : (
    <AniLink cover direction="down" duration={0.66} bg="#fff" {...props}>
      {children}
    </AniLink>
  )
}
export default Link
